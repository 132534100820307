import {
    storeNameNormalColumn,
    storeSnNormalColumn,
    dateNormalColumn,
} from '@/components/tablePage/common/normalColumn';

// 巡店记录-表头
const patrolStoreRecordColumns = [
    storeNameNormalColumn(),
    storeSnNormalColumn(),
    { title: '装修类型', key: '_buildStoreType', minWidth: 120 },
    {
        title: '门头照',
        minWidth: 130,
        key: '_headImageUrl',
        render: (h, p) => {
            if (!p.row._headImageUrl || p.row._headImageUrl.length === 0) {
                return h('div', '-');
            }
            let imgList = (p.row._headImageUrl || []).map((item) => {
                return h(
                    'div',
                    {
                        class: 'mr-5 mt-2 mb-2',
                        style: {
                            position: 'relative',
                            width: '110px',
                            height: '110px',
                        },
                    },
                    [
                        h('img', {
                            class: 'v-viewer-img',
                            attrs: {
                                src: item,
                                key: item,
                            },
                            style: {
                                width: '110px',
                                height: '110px',
                            },
                        }),
                        h('Icon', {
                            attrs: { type: 'ios-add-circle-outline' },
                            style: { 'z-index': 1, position: 'absolute', bottom: '2px', right: '2px', color: '#fff' },
                        }),
                    ],
                );
            });
            return h(
                'div',
                {
                    class: 'v-viewer-container pt-5 pb-5',
                    directives: [
                        {
                            name: 'viewer',
                            value: { movable: false },
                            expression: '{ movable: false }',
                        },
                    ],
                    style: {
                        width: '130px',
                    },
                },
                imgList,
            );
        },
    },
    {
        title: '最近巡店照',
        minWidth: 360,
        key: 'lastTourImageUrls',
        render: (h, p) => {
            if (!p.row.lastTourImageUrls || p.row.lastTourImageUrls.length === 0) {
                return h('div', '-');
            }
            let imgList = (p.row.lastTourImageUrls || []).map((item) => {
                return h(
                    'div',
                    {
                        class: 'mr-5 mt-2 mb-2',
                        style: {
                            position: 'relative',
                            width: '110px',
                            height: '110px',
                        },
                    },
                    [
                        h('img', {
                            class: 'v-viewer-img',
                            attrs: {
                                src: item,
                                key: item,
                            },
                            style: {
                                width: '110px',
                                height: '110px',
                            },
                        }),
                        h('Icon', {
                            attrs: { type: 'ios-add-circle-outline' },
                            style: { 'z-index': 1, position: 'absolute', bottom: '2px', right: '2px', color: '#fff' },
                        }),
                    ],
                );
            });
            return h(
                'div',
                {
                    class: 'v-viewer-container pt-5 pb-5',
                    directives: [
                        {
                            name: 'viewer',
                            value: { movable: false },
                            expression: '{ movable: false }',
                        },
                    ],
                    style: {
                        width: '360px',
                    },
                },
                imgList,
            );
        },
    },
    {
        title: '季度',
        key: 'yearQuarter',
        minWidth: 120,
        render(h, p) {
            return h('div', null, `${p.row.year}年Q${p.row.quarter}`);
        },
    },
    { title: '本季已巡次数', key: '_tourCount', minWidth: 120 },
    dateNormalColumn('最近巡店时间', 'lastTourDate'),
];

export { patrolStoreRecordColumns };
