<template>
    <div v-loading="pageLoading">
        <PageHeader title="门店新零售补贴" hidden-breadcrumb> </PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <table-form
                :formList="formList"
                :initParam="initParam"
                @on-reset="onReset"
                @on-submit="onSubmit"
                @on-export="exportInfo"
                ref="table-form"
            >
                <Button class="ivu-ml-8" slot="extraBtn" type="error">导出</Button>
            </table-form>
            <table-page
                ref="selection-table"
                @onPageChange="onPageChange"
                @onPageSizeChange="onPageSizeChange"
                :list="list"
            />
        </Card>
    </div>
</template>

<script>
import tablePage from '@/components/tablePage';
import tableForm from '@/components/tableForm';
import { subsidiesList, subsidiesListExport } from '@/api/statistics';
import { getQuarterByMonth, jumpPage } from '@/libs/util';
import moment from 'moment';
import { quarterNow, yearNow, initParam, quarterList } from '../rentSubsidy/const';

export default {
    components: {
        tableForm,
        tablePage,
    },
    data() {
        return {
            initParam,
            curYear: yearNow, // 当前年份
            curQuater: quarterNow, // 当前是第几季度
            pageLoading: false,
            quarterNow: null, // 当前查询的季度
            formList: [
                {
                    title: '门店',
                    key: 'storeStr',
                    width: '230px',
                    type: 'input',
                    placeholder: '请输入门店名称/联系人/联系方式',
                },
                {
                    title: '季度',
                    key: 'quarter',
                    type: 'select',
                    placeholder: '请选择',
                    value: initParam.quarter,
                    valueActivation: true,
                    onChange: this.handleQuaterChange,
                    options: quarterList,
                    filterable: true,
                },
            ],
            list: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                loading: false,
                columns: [
                    {
                        title: '门店名称',
                        key: 'storeName',
                        align: 'center',
                        minWidth: 150,
                        fixed: 'left',
                    },
                    {
                        title: '联系人',
                        key: 'ownerName',
                        align: 'center',
                        minWidth: 120,
                    },
                    {
                        title: '联系方式',
                        key: 'ownerPhone',
                        align: 'center',
                        minWidth: 140,
                    },
                    {
                        title: '当前档次',
                        key: 'currentLevel',
                        align: 'center',
                        minWidth: 150,
                    },
                    {
                        title: '主营品牌',
                        key: 'majorBrand',
                        align: 'center',
                        minWidth: 150,
                    },
                    {
                        title: '合同生效时间',
                        key: 'contractFinishTime',
                        align: 'center',
                        minWidth: 120,
                        render: (h, p) => {
                            return [moment(p.row.contractFinishTime).format('YYYY-MM')];
                        },
                    },
                    {
                        title: '知轮签约',
                        key: 'zhilunSign',
                        align: 'center',
                        minWidth: 160,
                        render: (h, p) => {
                            return h('span', {}, p.row.zhilunSign ? '是' : '否');
                        },
                    },
                    {
                        title: '季度',
                        key: 'quarterStr',
                        align: 'center',
                        minWidth: 100,
                    },
                    // {
                    //     title: '季度中策完成量',
                    //     key: 'zcQuarterFinishNum',
                    //     align: 'center',
                    //     minWidth: 150,
                    // },
                    // {
                    //     title: '季度知轮完成量',
                    //     key: 'zhilunQuarterFinishNum',
                    //     align: 'center',
                    //     minWidth: 150,
                    // },
                    {
                        title: '季度完成量/季度总任务量',
                        align: 'center',
                        minWidth: 150,
                        render: (h, p) => {
                            return h('span', `${p.row.quarterFinishNumber || 0}/${p.row.quarterTotalTaskNumber || 0}`);
                        },
                    },
                    {
                        title: '季度任务状态',
                        key: 'quarterFinishStatus',
                        align: 'center',
                        minWidth: 120,
                        render: (h, p) => {
                            const statusStr = {
                                0: '未完成',
                                1: '已完成',
                            }[p.row.quarterFinishStatus];
                            return h('span', statusStr);
                        },
                    },
                    {
                        title: '季度店检状态',
                        key: 'storeInspectionStatus',
                        align: 'center',
                        minWidth: 120,
                        render: (h, p) => {
                            const statusStr = {
                                0: '不合格',
                                1: '合格',
                                2: this.quarterNow === quarterNow ? '未完成' : '待审核',
                            }[p.row.storeInspectionStatus];
                            return h('span', statusStr);
                        },
                    },
                    //{
                    //    title: '季度三包胎',
                    //    key: 'sanbaoNumber',
                    //    align: 'center',
                    //    minWidth: 120,
                    //    render: (h, p) => {
                    //        return h('span', p.row.sanbaoNumber === null ? '-' : p.row.sanbaoNumber);
                    //    },
                    //},
                    //{
                    //    title: '季度补贴金额',
                    //    key: 'calculateQuarterSubsidyMoney',
                    //    align: 'center',
                    //    minWidth: 120,
                    //    render: (h, p) => {
                    //        return h(
                    //            'span',
                    //            p.row.calculateQuarterSubsidyMoney === null ? '-' : p.row.calculateQuarterSubsidyMoney,
                    //        );
                    //    },
                    //},
                    //{
                    //    title: '季度应扣三包胎金额',
                    //    key: 'deductionAmount',
                    //    align: 'center',
                    //    minWidth: 120,
                    //    render: (h, p) => {
                    //        return h('span', p.row.deductionAmount === null ? '-' : p.row.deductionAmount);
                    //    },
                    //},
                    //{
                    //    title: '季度实扣三包胎金额',
                    //    key: 'actualDeductionAmount',
                    //    align: 'center',
                    //    minWidth: 120,
                    //    render: (h, p) => {
                    //        return h('span', p.row.actualDeductionAmount === null ? '-' : p.row.actualDeductionAmount);
                    //    },
                    //},
                    //{
                    //    title: '年底扣三包胎金额',
                    //    key: 'yearDeductionAmount',
                    //    align: 'center',
                    //    minWidth: 120,
                    //    render: (h, p) => {
                    //        return h('span', p.row.yearDeductionAmount === null ? '-' : p.row.yearDeductionAmount);
                    //    },
                    //},
                    {
                        title: '预计季度补贴',
                        key: 'quarterSubsidyMoney',
                        align: 'center',
                        minWidth: 120,
                        render: (h, p) => {
                            return h('span', p.row.quarterSubsidyMoney === null ? '-' : p.row.quarterSubsidyMoney);
                        },
                    },
                    {
                        title: '审核季度补贴',
                        key: 'verifySubsidyMoney',
                        align: 'center',
                        minWidth: 120,
                        render: (h, p) => {
                            return h('span', p.row.verifySubsidyMoney === null ? '-' : p.row.verifySubsidyMoney);
                        },
                    },
                    {
                        title: '审核状态',
                        key: 'verifyStatus',
                        align: 'center',
                        minWidth: 130,
                        render: (h, p) => {
                            let badge = h('Badge', {
                                props: {
                                    color: {
                                        0: 'red',
                                        1: 'blue',
                                        2: 'green',
                                        3: 'blue',
                                        4: 'orange',
                                    }[p.row.verifyStatus],
                                    text: {
                                        0: '未审核',
                                        1: '审核中',
                                        2: '已审核',
                                        3: '挂起',
                                        4: '店检待审核',
                                    }[p.row.verifyStatus],
                                },
                            });
                            return h('div', [badge]);
                        },
                    },
                    //{
                    //    title: '操作',
                    //    key: 'oprate',
                    //    align: 'center',
                    //    width: 110,
                    //    fixed: 'right',
                    //    render: (h, p) => {
                    //        return [
                    //            renderButton(h, { text: '三包胎明细', type: 'warning' }, () => {
                    //                this.handleGoDetail(p.row);
                    //            }),
                    //        ];
                    //    },
                    //},
                ],
                data: [],
            },
        };
    },

    mounted() {
        this.getData();
        this.$bus.$on('getData', () => {
            this.resetData();
        });
    },
    methods: {
        onReset() {
            this.formList[1].value = initParam.quarter;
            this.handleQuaterChange(initParam.quarter);
        },
        handleQuaterChange(val) {
            this.formList[1].value = val;
            let arr = [
                {
                    title: '季度任务状态',
                    key: 'quarterFinishStatus',
                    type: 'select',
                    placeholder: '请选择',
                    options: [
                        {
                            name: '已完成',
                            id: 1,
                        },
                        {
                            name: '未完成',
                            id: 0,
                        },
                    ],
                },
                {
                    title: '季度店检状态',
                    key: 'storeInspectionStatus',
                    type: 'select',
                    placeholder: '请选择',
                    options: [
                        {
                            name: '合格',
                            id: 1,
                        },
                        {
                            name: '不合格',
                            id: 0,
                        },
                        {
                            name: '待审核',
                            id: 2,
                        },
                    ],
                },
            ];
            if (!val) {
                if (this.formList.length === 2) return;
                this.formList.splice(2, 2);
                return;
            }
            let currentQuater = getQuarterByMonth();
            let currentYear = yearNow;
            let [selYear, selQuater] = val.split(':');
            if (selYear < currentYear) {
                // 历史年度
                if (this.formList.length > 2) return;
                this.formList.splice(2, 0, arr[0], arr[1]);
            } else {
                if (selQuater < currentQuater) {
                    // 相同年份，历史季度
                    if (this.formList.length > 2) return;
                    this.formList.splice(2, 0, arr[0], arr[1]);
                } else {
                    if (this.formList.length === 2) return;
                    this.formList.splice(2, 2);
                }
            }
        },
        handleGoDetail(row) {
            jumpPage({
                path: '/sub-vue2/statistics/rentSubsidy/threePackTireDetail',
                params: {
                    storeId: row.storeId,
                },
            });
        },
        resetData() {
            this.getData();
        },
        getData() {
            this.list.loading = true;
            let p = this.$refs['table-form'].getFormData();
            if (!p.quarter) {
                p.quarter = quarterNow;
                p.year = new Date().getFullYear();
            } else {
                const quarterArr = p.quarter.split(':');
                const [year, quarterNum] = quarterArr;
                p.year = parseInt(year);
                p.quarter = parseInt(quarterNum);
            }
            this.quarterNow = p.quarter;

            let params = {
                current: this.list.current,
                size: this.list.size,
                ...p,
                flag: false,
            };
            // delete params.provinceId
            console.log('params after =', params);
            subsidiesList(params).then(
                (data) => {
                    this.list.total = Number(data.total);
                    this.list.data = data.list || [];
                    this.list.loading = false;
                },
                () => {
                    this.list.loading = false;
                },
            );
        },
        exportData() {
            let p = this.$refs['table-form'].getFormData();
            if (!p.quarter) {
                p.quarter = quarterNow;
                p.year = new Date().getFullYear();
            } else {
                const quarterArr = p.quarter.split(':');
                const [year, quarterNum] = quarterArr;
                p.year = parseInt(year);
                p.quarter = parseInt(quarterNum);
            }
            this.quarterNow = p.quarter;

            let params = {
                ...p,
                flag: false,
            };
            this.$Message.loading({
                content: '导出中...',
                duration: 0,
            });
            // delete params.provinceId
            subsidiesListExport(params).then(
                (res) => {
                    this.list.loading = false;
                    this.isExporting = false;
                    if (!res) {
                        this.$Message.destroy();
                        this.$Message.warning('暂无数据');
                        return false;
                    }
                    let aLink = document.createElement('a');
                    aLink.href = res || '';
                    // console.log('href ===', res)
                    document.body.appendChild(aLink);
                    aLink.click();
                    document.body.removeChild(aLink);
                    this.$Message.destroy();
                    this.$Message.success('导出成功');
                },
                () => {
                    this.$Message.destroy();
                    this.$Message.warning({
                        title: '导出失败 ！',
                    });
                    this.list.loading = false;
                    this.isExporting = false;
                },
            );
        },

        onPageChange(val) {
            this.list.current = val;
            this.getData();
        },

        onPageSizeChange(val) {
            this.list.current = 1;
            this.list.size = val;
            this.getData();
        },

        onSubmit() {
            this.list.current = 1;
            this.getData();
        },

        exportInfo() {
            this.exportData();
        },
    },
};
</script>

<style lang="less" scoped>
/deep/ .ivu-form-item {
    &:nth-child(1) {
        width: 325px;
    }
}
</style>
