<!--
 * @Author: chengleilei
 * @Date: 2024-05-20 10:29:57
 * @Description: 门店店检年度统计
-->
<template>
    <div>
        <PageHeader title="门店店检年度统计" hidden-breadcrumb></PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <Tabs :value="currentTab" :animated="true" @on-click="handleTabClick" style="margin-top: -12px">
                <TabPane v-for="item in tabList" :key="item.name" :label="item.label" :name="item.name">
                    <YearTab
                        :ref="item.name + 'yearTabRef'"
                        :formList="item.formList"
                        :columns="item.columns"
                        :currentTab="item.name"
                    />
                </TabPane>
            </Tabs>
        </Card>
        <DetailModal ref="dialogRef" />
    </div>
</template>

<script>
import YearTab from './components/YearTab';
import DetailModal from './components/DetailModal';
import {
    formList2021,
    formList2022,
    formList2023,
    formList2024,
    formList2025,
    columns2021,
    columns2022,
    columns2023,
    columns2024,
    columns2025,
} from './const';

export default {
    components: { YearTab, DetailModal },
    data() {
        return {
            currentTab: '2025', // 当前tab
            tabList: [
                { label: '2025年', name: '2025', formList: formList2025, columns: columns2025(this.openDetailDialog) },
                { label: '2024年', name: '2024', formList: formList2024, columns: columns2024(this.openDetailDialog) },
                { label: '2023年', name: '2023', formList: formList2023, columns: columns2023(this.openDetailDialog) },
                { label: '2022年', name: '2022', formList: formList2022, columns: columns2022(this.openDetailDialog) },
                { label: '2021年', name: '2021', formList: formList2021, columns: columns2021 },
            ],
        };
    },
    methods: {
        // 切换tab
        handleTabClick(e) {
            if (e === this.currentTab) {
                return false;
            }
            this.currentTab = e;
            this.$refs[e + 'yearTabRef'][0].getData();
        },
        // 打开详情弹窗
        openDetailDialog(row, i) {
            this.$refs.dialogRef.open(row, this.currentTab, i);
        },
    },
    mounted() {
        this.$refs['2025yearTabRef'][0].getData();
    },
};
</script>
