<template>
    <Modal v-model="modal" :width="750" :title="title" @on-cancel="onCancel" footer-hide>
        <Spin size="large" fix v-if="loading">加载中...</Spin>
        <Tabs :value="currentTab" type="card" v-if="!loading">
            <TabPane v-for="(item, index) in tabList" :key="item.name" :label="item.label" :name="item.name">
                <p class="tc mt-20 mb-20" v-if="storeInspectionDetails[index].inspectionStatus === 1">暂无记录</p>
                <DetailInfo
                    v-else
                    :ref="item.name + 'DetailRef'"
                    :mapSign="item.name"
                    :detailData="storeInspectionDetails[index]"
                />
            </TabPane>
        </Tabs>
    </Modal>
</template>

<script>
import { storeInspectionQuarterDetail } from '@/api/statistics';
import DetailInfo from './DetailInfo';
import { getLabelByValue, inspectionStatusDict } from '@/dicts';

export default {
    components: { DetailInfo },
    data() {
        return {
            modal: false,
            loading: true, // 加载中
            title: '', // 弹窗标题
            params: {}, // 获取详情的参数
            currentTab: 'tab1', // 当前tab
            tabList: [
                { label: '1', name: 'tab1' },
                { label: '2', name: 'tab2' },
                { label: '3', name: 'tab3' },
            ],
            storeInspectionDetails: [], // 店检详情
        };
    },
    mounted() {},
    methods: {
        // 打开弹窗
        open(row, year, quarter) {
            this.title = row.storeName;
            this.params = {
                storeId: row.storeId,
                year,
                quarter,
            };
            this.getDetail();
            this.modal = true;
        },
        // 获取详情
        async getDetail() {
            this.loading = true;
            try {
                const res = await storeInspectionQuarterDetail(this.params);
                this.tabList = res.map((item, index) => {
                    return {
                        label: `${item.month}月 - ${getLabelByValue(inspectionStatusDict, item.inspectionStatus)}`,
                        name: `tab${index + 1}`,
                    };
                });
                this.storeInspectionDetails = res;
                this.loading = false;
            } catch (error) {
                this.loading = false;
            }
        },
        // 弹窗取消
        onCancel() {
            this.modal = false;
        },
    },
};
</script>
