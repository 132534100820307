const monthNow = new Date().getMonth() + 1;
const quarterNow = Math.floor(monthNow % 3 == 0 ? monthNow / 3 : monthNow / 3 + 1);
const yearNow = new Date().getFullYear();
const initParam = { year: yearNow, quarter: `${yearNow}:${quarterNow}` };

const quarterList = [
    { name: '2025年Q1', id: '2025:1' },
    { name: '2025年Q2', id: '2025:2' },
    { name: '2025年Q3', id: '2025:3' },
    { name: '2025年Q4', id: '2025:4' },
    { name: '2024年Q1', id: '2024:1' },
    { name: '2024年Q2', id: '2024:2' },
    { name: '2024年Q3', id: '2024:3' },
    { name: '2024年Q4', id: '2024:4' },
    { name: '2023年Q1', id: '2023:1' },
    { name: '2023年Q2', id: '2023:2' },
    { name: '2023年Q3', id: '2023:3' },
    { name: '2023年Q4', id: '2023:4' },
    { name: '2022年Q1', id: '2022:1' },
    { name: '2022年Q2', id: '2022:2' },
    { name: '2022年Q3', id: '2022:3' },
    { name: '2022年Q4', id: '2022:4' },
];

export { monthNow, quarterNow, yearNow, initParam, quarterList };
