<!--
* @Author: zhangping
* @Date: 2025-02-10 15:27:23
* @Description: 巡店记录
-->

<template>
    <div>
        <PageHeader title="巡店记录" hidden-breadcrumb></PageHeader>
        <Card :bordered="false" dis-hover class="ivu-mt">
            <TableFormNew ref="tableFormNewRef" :formList="formList" @onSubmit="onSubmit" @onReset="onReset" />
            <TablePage :list="tableConfig" @onPageChange="onPageChange" @onPageSizeChange="onPageSizeChange" />
        </Card>
    </div>
</template>

<script>
import _ from 'lodash';
import TableFormNew from '@/components/tableFormNew';
import TablePage from '@/components/tablePage';
import { getLabelByValue, storeDecorationStatusTwoDict, patrolStoreStatusDict } from '@/dicts';
import { clearInvalidKey, getQuarterOptions, getCurrYearQuarter } from '@/libs/util.tool';
import { renderButton, jumpPage } from '@/libs/util';
import { patrolStoreRecordColumns } from './const';
import { apiPatrolStoreRecordList } from '@/api/statistics';
export default {
    components: { TableFormNew, TablePage },
    data() {
        return {
            formList: [
                { type: 'input', label: '维护人', prop: 'salesmanName' },
                { type: 'input', label: '维护人手机', prop: 'salesmanPhone' },
                { type: 'input', label: '门店', prop: 'storeInfo', placeholder: '请输入门店名称/编号/联系人/联系方式' },
                {
                    type: 'select',
                    label: '装修类型',
                    prop: 'buildStoreType',
                    options: storeDecorationStatusTwoDict,
                },
                {
                    type: 'select',
                    label: '季度',
                    prop: 'yearQuarter',
                    options: getQuarterOptions(2025),
                    value: getCurrYearQuarter(),
                },
                {
                    type: 'select',
                    label: '是否已巡',
                    prop: 'isTour',
                    options: patrolStoreStatusDict,
                    isConvertToBoolean: true,
                },
            ],
            tableConfig: {
                current: 1,
                size: window.$pageSize,
                total: 0,
                columns: [
                    ...patrolStoreRecordColumns,
                    {
                        title: '操作',
                        width: 140,
                        fixed: 'right',
                        render: (h, p) => {
                            let btnList = [
                                renderButton(h, { text: '详情', type: 'primary' }, () => {
                                    this.checkDetails(p.row);
                                }),
                            ];
                            return btnList;
                        },
                    },
                ],
                data: [],
                loading: false,
            },
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        // 获取筛选条件参数
        getScreenParams() {
            const { current, size } = this.tableConfig;
            let formData = this.$refs['tableFormNewRef'].getFormData();
            formData.deptId = formData?.dept?.[0];
            formData.regionId = formData?.dept?.[1];
            delete formData?.dept;
            let _yearQuarter = formData?.yearQuarter?.split(':');
            formData.year = _yearQuarter?.[0] ? Number(_yearQuarter?.[0]) : null;
            formData.quarter = _yearQuarter?.[1] ? Number(_yearQuarter?.[1]) : null;
            delete formData?.yearQuarter;
            const params = {
                ...clearInvalidKey(formData),
                current,
                size,
            };
            return params;
        },
        // 获取列表数据
        async getData() {
            try {
                this.tableConfig.loading = true;
                const res = await apiPatrolStoreRecordList(this.getScreenParams());
                this.tableConfig.data = res.list.map((item) => {
                    item._buildStoreType = getLabelByValue(storeDecorationStatusTwoDict, item.buildStoreType);
                    item._headImageUrl = item.headImageUrl ? [item.headImageUrl] : null;
                    item._tourCount = item.tourCount || item.tourCount === 0 ? item.tourCount : '-';
                    return item;
                });
                this.tableConfig.total = Number(res.total);
                this.tableConfig.loading = false;
            } catch (error) {
                this.tableConfig.data = [];
                this.tableConfig.total = 0;
                this.tableConfig.loading = false;
            }
        },
        // 查询
        onSubmit() {
            this.tableConfig.current = 1;
            this.getData();
        },
        // 重置
        onReset() {
            this.tableConfig.current = 1;
            this.tableConfig.size = window.$pageSize;
            this.getData();
        },
        onPageChange(val) {
            this.tableConfig.current = val;
            this.getData();
        },
        onPageSizeChange(val) {
            this.tableConfig.current = 1;
            this.tableConfig.size = val;
            this.getData();
        },
        // 查看详情
        checkDetails(row) {
            const { storeId, storeIdType, year, quarter, buildStoreType } = row;
            jumpPage({
                path: '/sub-vue2/statistics/patrolStoreRecord/detail',
                params: { storeId, storeIdType, year, quarter, buildStoreType },
            });
        },
    },
};
</script>

<style lang="less" scoped></style>
