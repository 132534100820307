<template>
    <div class="am-main" v-if="detailData.month">
        <Form label-colon :label-width="100">
            <Row>
                <Col push="2" span="10">
                    <FormItem label="门头照片">
                        <span v-viewer="{ movable: false, zIndex: 1048 }" class="v-viewer-container">
                            <img :src="detailData.storeHeadImgUrl" class="v-viewer-img am-main-img" />
                        </span>
                    </FormItem>
                </Col>
                <Col push="2" span="10" v-if="detailData.lastHeadImgUrl">
                    <FormItem label="上次门头照">
                        <span v-viewer="{ movable: false, zIndex: 1048 }" class="v-viewer-container">
                            <img :src="detailData.lastHeadImgUrl" class="v-viewer-img am-main-img" />
                        </span>
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col push="2" span="24">
                    <FormItem label="本次店检照">
                        <span v-viewer="{ movable: true, zIndex: 1048 }" class="v-viewer-container mt5">
                            <img
                                :src="url"
                                class="v-viewer-img am-main-img mr10 mb10"
                                :key="url"
                                v-for="url in detailData.currentImgUrlList"
                            />
                        </span>
                    </FormItem>
                </Col>
            </Row>
        </Form>
        <Row>
            <Col push="2" span="20">
                <div :id="`s-si-di-${mapSign}`" class="am-main-map ivu-mt-16 ivu-mb-16"></div>
            </Col>
        </Row>
        <Form label-colon :label-width="140">
            <Row>
                <Col push="2" span="20">
                    <FormItem label="本次提交时间">{{ detailData.applyTime }} </FormItem>
                </Col>
            </Row>
            <template v-if="detailData.businessManAuditResult">
                <Divider />
                <Row>
                    <Col push="2" span="20">
                        <FormItem label="业务主管审核结果">{{ detailData.businessManAuditResult }} </FormItem>
                    </Col>
                </Row>
            </template>
            <Row>
                <Col push="2" span="10" v-if="detailData.channelManAuditResult">
                    <FormItem label="渠道员审核结果">
                        {{ detailData.channelManAuditResult }}
                        <Tag v-if="detailData.appealStatus === 3" color="green" class="ml-10">申诉审核合格</Tag>
                    </FormItem>
                </Col>
                <Col push="2" span="10" v-if="detailData.channelManAuditResult === '合格'">
                    <FormItem label="门店类型">{{ detailData.auditStoreTypeStr }} </FormItem>
                </Col>
            </Row>
            <Row v-if="detailData.rejectReason && detailData.appealStatus !== 3">
                <Col push="2" span="20">
                    <Alert>
                        <p class="wbba">驳回原因：{{ detailData.rejectReason }}</p>
                    </Alert>
                </Col>
            </Row>
        </Form>
    </div>
</template>

<script>
/* eslint-disable no-undef */
let map = {}; // 地图实例
let storeMarkersLayer = []; // 门店标记列表图层

export default {
    props: {
        mapSign: {
            type: String,
            default: '',
        },
        detailData: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {};
    },
    beforeDestroy() {
        map[`staSiDi${this.mapSign}`] = null;
    },
    mounted() {
        map[`staSiDi${this.mapSign}`] = new AMap.Map(`s-si-di-${this.mapSign}`, {
            zoom: 12,
            scrollWheel: true,
        });
        this.initStoreMarker();
    },
    methods: {
        initStoreMarker() {
            let { currentLatitude, currentLongitude, lastLatitude, lastLongitude, storeLatitude, storeLongitude } =
                this.detailData;
            // 创建一个 Icon
            const storeIcon = new AMap.Icon({
                size: new AMap.Size(24, 28), // 图标尺寸
                image: 'https://zc-resource.oss-cn-hangzhou.aliyuncs.com/static/imgs/store-icon.png', // 图标的地址
                imageSize: new AMap.Size(24, 28), // 图标所用图片大小
                imageOffset: new AMap.Pixel(0, 0), // 图标取图偏移量
            });
            // 设置地图中心点，即门店地址
            map[`staSiDi${this.mapSign}`].setCenter([storeLongitude, storeLatitude]);
            // 清除 marker
            if (storeMarkersLayer && storeMarkersLayer.length > 0) {
                map[`staSiDi${this.mapSign}`].remove(storeMarkersLayer);
                storeMarkersLayer = [];
            }
            // 门店的标记图层
            let marker1 = new AMap.Marker({
                width: 24,
                height: 28,
                position: new AMap.LngLat(storeLongitude, storeLatitude),
                title: '门店坐标',
                icon: storeIcon,
            });
            marker1.setLabel({
                content: "<div class='a-sia-am-label a-sia-am-store'>门店坐标</div>",
                direction: 'top',
            });
            storeMarkersLayer.push(marker1);
            // 本次的标记图层
            let marker2 = new AMap.Marker({
                width: 24,
                height: 28,
                position: new AMap.LngLat(currentLongitude, currentLatitude),
                title: '本次坐标',
                icon: storeIcon,
            });
            marker2.setLabel({
                content: "<div class='a-sia-am-label a-sia-am-current'>本次坐标</div>",
                direction: 'top',
            });
            storeMarkersLayer.push(marker2);
            if (lastLongitude && lastLatitude) {
                // 上次的标记图层
                let marker = new AMap.Marker({
                    width: 24,
                    height: 28,
                    position: new AMap.LngLat(lastLongitude, lastLatitude),
                    title: '上次坐标',
                    icon: storeIcon,
                });
                marker.setLabel({
                    content: "<div class='a-sia-am-label a-sia-am-last'>上次坐标</div>",
                    direction: 'top',
                });
                storeMarkersLayer.push(marker);
            }
            map[`staSiDi${this.mapSign}`].add(storeMarkersLayer);
            map[`staSiDi${this.mapSign}`].setFitView();
        },
    },
};
</script>

<style lang="less" scoped>
.ivu-form-item {
    margin-bottom: 0;
}
.am-main {
    min-height: 520px;
    position: relative;
    .am-main-arrowIcon {
        position: absolute;
        top: 45%;
        cursor: pointer;
        z-index: 9;
    }
    .am-main-arrowIconPrev {
        left: 0;
    }
    .am-main-arrowIconNext {
        right: 0;
    }
    .am-main-arrowIconDisabled {
        opacity: 0.4;
        pointer-events: none;
    }
    .am-main-img {
        width: 110px;
        height: 110px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-top: 10px;
        cursor: pointer;
        border-radius: 4px;
    }
    .am-main-map {
        height: 160px;
        background: #fcf9f2
            url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAfQAAAH0AgMAAAC2uDcZAAAADFBMVEX////////////////1pQ5zAAAABHRSTlMAgP/AWuZC2AAAAVhJREFUeAFiYGAQYGDEQjAB2rcDC4BiGIqiU7abdKlO2QkeIClyPsDHweMKtOPHIJ1Op6/w7Y4fdqfT6VpndzqdrnV2p9PpWmd3Oj3qWndSoKp+2J1Op7vr7E6n07XO7nQ6XevsTqfTtc7udPo4/f787E6n0911dqfT6VpndzqdrnV2p9PpWmd3Ot27Ce8m6HS6u85dR6fTtU7r6HS61mkdnU7XOrvT6XTvJuxOp9PddXan0+laZ3c6na51dDpd67SOTqd7N+HdBJ1Od9e56+h0utZpHZ1O1zq70+l0rbM7nU73bsLudDrdXWd3Ol3rtI5Op2ud1tHpdK3TOjqd7t2EdxN0Ot1dZ3c6na51dqfT6VpndzqdrnV2p9Pp3k3Q6XR3nbuOTqdrndbR6XSt0zo6na51Wken072bsDudTnfX2Z1Op2ud3el0utbZnU7XOq2j0+t0uncTD1gO4zoT5doZAAAAAElFTkSuQmCC);
    }
    .am-main-appealReason {
        display: flex;
        .am-main-appealReason-left {
            width: 70px;
        }
        .am-main-appealReason-right {
            flex: 1;
            word-wrap: break-word;
            word-break: break-all;
            overflow-wrap: break-word;
        }
    }
}
</style>

<style lang="less">
.a-sia-am-label {
    position: relative;
    top: 0;
    right: 0;
    min-width: 0;
    color: #fff;
    font-size: 14px;
    text-align: center;
    padding: 6px;
    border-radius: 4px;
}
.a-sia-am-last {
    background: #fd932c;
}
.a-sia-am-current {
    background: #ff1d1d;
}
.a-sia-am-store {
    background: #297ee3;
}
.amap-marker-label {
    border: 0;
    background-color: transparent;
}
</style>
