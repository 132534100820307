import Vue from 'vue';
import App from './App.vue';
import router from './router';
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import iViewPro from '@/libs/iview-pro/iview-pro.min.js';
import './libs/iview-pro/iview-pro.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './styles/index.less';
// 插件
import plugins from '@/plugins';
// 指令
import './directives/directives';
// store
import store from '@/store/index';
// vxe-table
import 'xe-utils';
import VXETable from 'vxe-table';
import 'vxe-table/lib/index.css';

import preview from 'vue-photo-preview';
import '@/assets/photo-preview-skin/skin.css';
// 图片预览
import VueViewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';

Vue.use(preview, {
    bgOpacity: 0.9,
    fullscreenEl: true,
    zoomEl: true,
    shareEl: true,
    counterEl: true,
    arrowEl: true,
    preloaderEl: true,
    tapToClose: true,
    shareButtons: [
        {
            id: 'download',
            label: '下载图片',
            url: '{{raw_image_url}}',
            download: true,
        },
    ],
});

// 内置组件
import iLink from '@/components/link';
import ZkTable from 'vue-table-with-tree-grid';
import './plugins/toggleTip';

Vue.use(ViewUI);
Vue.use(ElementUI);
Vue.use(iViewPro);
Vue.use(plugins);
Vue.use(VXETable);
Vue.use(ZkTable);
Vue.use(VueViewer);

Vue.component('i-link', iLink);

function render(props = {}) {
    new Vue({
        router,
        store,
        beforeCreate() {
            Vue.prototype.$bus = this;
        },
        render: (h) => h(App),
    }).$mount(props?.container?.querySelector('#app') || '#app');
}

// 独立运行时
if (!window.__POWERED_BY_QIANKUN__) {
    render();
}

// 生命周期的钩子函数
// 导出第一次进入当前子应用的钩子函数
export async function bootstrap() {}
// 导出每次创建挂载时的钩子函数
export async function mount(props) {
    window.$basePageStore = props.pageStore;
    window.$baseAccountStore = props.accountStore;
    render(props);
}
// 导出每次销毁时的钩子函数
export async function unmount() {}
