<!--
* @Author: zhangping
* @Date: 2025-02-10 15:42:02
* @Description: 巡店记录-详情
-->
<template>
    <div>
        <PageHeader title="季度巡店详情" hidden-breadcrumb>
            <div slot="back" @click="jumpPrevPage">
                <Icon type="md-arrow-back" />
            </div>
        </PageHeader>
        <Spin fix v-if="loading">加载中...</Spin>
        <div class="ivu-mt" v-if="detailInfo">
            <Card :bordered="false" dis-hover title="门店信息">
                <Form :label-width="100" label-colon>
                    <Row>
                        <Col span="12">
                            <FormItem label="门店名称"> {{ detailInfo.storeName }} </FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="装修类型">
                                {{ detailInfo.buildStoreTypeStr }}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span="12">
                            <FormItem label="联系人"> {{ detailInfo.ownerName }} </FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="联系方式"> {{ detailInfo.ownerPhone }} </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span="12">
                            <FormItem label="经销商"> {{ detailInfo.distributorName }} </FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="维护人"> {{ detailInfo.salesmanName || '-' }} </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span="12">
                            <FormItem label="注册类型">
                                {{ detailInfo.storeRegisterTypeStr }}
                            </FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="门店来源"> {{ detailInfo.storeSource }} </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span="12">
                            <FormItem label="门店编号">{{ detailInfo.storeSn || '-' }}</FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span="24">
                            <FormItem label="门店地址"> {{ detailInfo.storeAddress }} </FormItem>
                        </Col>
                    </Row>
                </Form>
            </Card>
            <Card :bordered="false" dis-hover title="季度巡店记录" v-if="detailInfo.distributorTourQuarterRecordVOS">
                <ul>
                    <li v-for="(record, index) in detailInfo.distributorTourQuarterRecordVOS" :key="index" class="mb-8">
                        <Alert>
                            <span>{{ record.tourDate }} </span>
                            <span v-if="record.distance">距离{{ record.distance }}m </span>
                            <span>{{ record.tourSalesmanName }}</span>
                        </Alert>
                        <span v-viewer="{ movable: false, zIndex: 100000 }" class="v-viewer-container">
                            <img
                                v-for="item in record.tourImageUrls"
                                :key="item"
                                :src="item"
                                class="v-viewer-img mr10 mb10"
                            />
                        </span>
                        <p v-if="record.tourRemark">{{ record.tourRemark }}</p>
                    </li>
                </ul>
            </Card>
        </div>
    </div>
</template>

<script>
import { apiStoreTourQuarterDetail } from '@/api/statistics';
import { getLabelByValue, storeDecorationStatusTwoDict, storeRegisterTypeDict } from '@/dicts.js';
import { jumpPage, transPathToName } from '@/libs/util';

export default {
    data() {
        return {
            storeId: this.$route.query.storeId,
            year: this.$route.query.year,
            quarter: this.$route.query.quarter,
            storeIdType: this.$route.query.storeIdType,
            buildStoreType: this.$route.query.buildStoreType,
            loading: false,
            detailInfo: null,
        };
    },
    mounted() {
        this.getDetail();
    },
    methods: {
        jumpPrevPage() {
            window.$basePageStore.closeTab(transPathToName(location.pathname));
            jumpPage({ path: '/sub-vue2/statistics/patrolStoreRecord/list' });
        },
        // 获取详情
        async getDetail() {
            try {
                this.loading = true;
                const params = {
                    storeId: this.storeId,
                    year: this.year,
                    quarter: this.quarter,
                    storeIdType: this.storeIdType,
                    buildStoreType: this.buildStoreType,
                };
                const res = await apiStoreTourQuarterDetail(params);
                this.loading = false;
                res.buildStoreTypeStr = getLabelByValue(storeDecorationStatusTwoDict, res.buildStoreType);
                res.storeRegisterTypeStr = getLabelByValue(storeRegisterTypeDict, res.storeRegisterType);
                this.detailInfo = res;
            } catch (error) {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="less" scoped>
.ivu-form-item {
    margin-bottom: 0;
}
</style>
